import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";
import 'animate.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './styles/main.scss';

import Home from './pages/home/Home.js';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="/profil/tatianka" replace />,
    },
    {
        path: "/profil/:name",
        element: <Home />,
    },
    {
        path: "*",
        element: <Navigate to="/profil/tatianka" replace />,
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);