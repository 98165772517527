import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './MainHome.scss';
import styles from './Home.modules.scss';
import { MDBBtn, MDBContainer, MDBProgress, MDBProgressBar, MDBSpinner } from 'mdb-react-ui-kit';
import BackgroundSlider from 'react-background-slider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';

const images = require.context('../../images/slider', true);
const imageList = images.keys().map(image => images(image));

const importAvatar = (name) => {
    try {
        return require(`../../images/avatars/${name.toLowerCase()}.jpg`);
    } catch (err) {
        return require('../../images/avatar.jpg');
    }
};

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

function Home() {
    const { name } = useParams();
    const capitalizedName = capitalize(name);
    const [, setIsTabActive] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [progress, setProgress] = useState(0);
    const [counter, setCounter] = useState(60);
    const [avatar, setAvatar] = useState(null);
    const redirectLink = 'https://track-on.pl/p/u0Za/zZ5L/LcHe';

    useEffect(() => {
        setAvatar(importAvatar(name));
    }, [name]);

    useEffect(() => {
        const connectionCookie = Cookies.get('isConnected');
        if (connectionCookie) {
            setIsConnected(true);
        } else {
            const interval = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        setIsConnected(true);
                        Cookies.set('isConnected', 'true', { expires: 1 });
                        clearInterval(interval);
                        return 100;
                    }
                    return prevProgress + 25;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        let interval;
        const handleVisibilityChange = () => {
            if (document.hidden) {
                setIsTabActive(false);
                interval = setInterval(() => {
                    document.title = document.title === `${capitalizedName} na Ciebie czeka!` ? `Seks-Randki - profil ${capitalizedName}` : `${capitalizedName} na Ciebie czeka!`;
                }, 500);
            } else {
                setIsTabActive(true);
                clearInterval(interval);
                document.title = `Seks-Randki - profil ${capitalizedName}`;
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearInterval(interval);
        };
    }, [capitalizedName]);

    useEffect(() => {
        if (isConnected) {
            const countdown = setInterval(() => {
                setCounter(prevCounter => {
                    if (prevCounter <= 1) {
                        clearInterval(countdown);
                        setTimeout(() => {
                            window.location.href = redirectLink;
                        }, 0);
                        return 0;
                    }
                    return prevCounter - 1;
                });
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [isConnected]);

    return (
        <div className='h-100'>
            <Helmet>
                <title>Seks-Randki - profil {capitalizedName}</title>
                <meta property="og:title" content={`Seks-Randki - profil ${capitalizedName}`} />
                <meta property="og:description" content={`Sprawdź profil ${capitalizedName} na Seks-Randki!`} />
                <meta property="og:image" content={avatar} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            <BackgroundSlider
                images={imageList}
                duration={6}
                transition={0.5}
            />
            <MDBContainer className='h-100'>
                <div className={styles.container}>
                    <div className={styles.title}>
                        {isConnected ?
                            <div>
                                <div
                                    className={styles.success + ` animate__animated animate__pulse animate__infinite`}>Sukces!
                                </div>
                                <div>Połączono z</div>
                            </div>
                            :
                            <div>
                                Trwa łączenie z
                            </div>
                        }
                    </div>
                    <div className={styles.contact}>
                        <img className={styles.avatar} src={avatar} alt={`avatar ${capitalizedName}`} />
                        <div className={styles.nameContainer}>
                            <span className={styles.name}>{capitalizedName}</span>
                        </div>
                    </div>
                    {!isConnected && (
                        <div className={styles.progressBarContainer}>
                            <MDBProgress height='5' className={styles.progressBar}>
                                <MDBProgressBar animated width={progress} bgColor='danger' valuemin={0} valuemax={100} />
                            </MDBProgress>
                            <MDBSpinner className={styles.progressBarLoader} color='danger'>
                                <span className='visually-hidden'>Loading...</span>
                            </MDBSpinner>
                        </div>
                    )}
                    {isConnected && (
                        <div className={styles.footerContainer}>
                            <MDBBtn color='danger' className={styles.footerButton + ` animate__slow animate__animated animate__headShake animate__infinite`} href={redirectLink}>
                                DOŁĄCZ
                                <FontAwesomeIcon className={styles.joinIcon + ` animate__slow animate__animated animate__jello animate__infinite`} icon={faMessage} />
                            </MDBBtn>
                            <div className={styles.footerContainerText}>
                                Zaproszenie wygaśnie za: <div className={styles.footerContainerCounter}>{counter}</div>
                            </div>
                        </div>
                    )}
                </div>
            </MDBContainer>
        </div>
    );
}

export default Home;